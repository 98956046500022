import { breakpoints } from '~/theme/breakpoints';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { StyledAlignContent } from '../AlignContent';
import { M75MediaTextProps } from './M75MediaText';
import { spacingValue } from '../ModuleContainer';
import { mq } from '~/lib/helpers/media-query.helper';

type StyledM75Props = Pick<M75MediaTextProps, 'spacingBottom' | 'spacingTop'>;

type StyledM75ContentProps = {
    backgroundColor: M75MediaTextProps['backgroundColor'];
    mediaAlignment: M75MediaTextProps['mediaHorizontalAlignment'];
    mediaSize: M75MediaTextProps['mediaSize'];
};

export const StyledM75Container = styled.div<StyledM75Props>(
    ({ theme, spacingBottom, spacingTop }) => ({
        marginTop: spacingTop ? spacingValue(spacingTop, theme) : 0,
        marginBottom: spacingBottom
            ? `calc(${theme.space[7]} + ${spacingValue(spacingBottom, theme)})`
            : 0,

        [mq(0, 'md')]: {
            marginBottom: spacingBottom ? spacingValue(spacingBottom, theme) : 0,
        },
    }),
);

export const StyledActions = styled.div(({ theme }) => ({
    display: 'grid',
    gap: theme.space['2'],
}));

export const StyledM75 = styled.div<StyledM75Props>(({ theme }) => ({
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
    maxWidth: theme.sizes.contentMaxWidth,
}));

export const StyledGridArea = styled.div<{ area: 'content' | 'media' }>(({ area }) => ({
    gridArea: area,
    position: 'relative',
}));

export const StyledMedia = styled.div<{ alignment: 'left' | 'center' | 'right' }>(
    ({ theme }) => ({
        a: {
            display: 'block',
        },
        [mq(0, 'md')]: {
            margin: '0 auto',
        },
        [breakpoints.md]: {
            position: 'relative',
            top: theme.space[7],
        },
    }),
    switchProp('alignment', {
        left: ({ theme }) => ({
            [breakpoints.md]: {
                left: theme.space[7],
            },
        }),
        right: ({ theme }) => ({
            [breakpoints.md]: {
                right: theme.space[7],
            },
        }),
    }),
);

export const StyledM75Content = styled.div<StyledM75ContentProps>(
    ({ mediaAlignment, theme }) => ({
        height: 'auto',
        boxSizing: 'border-box',
        width: '100%',
        margin: '0 auto',

        [mq(0, 'md')]: {
            display: 'grid',
            gridTemplateAreas: '"media" "content"',
            gridTemplateRows: '1fr',
            gridAutoColumns: 'unset !important',
        },

        [breakpoints.md]: {
            display: 'grid',
            gridAutoFlow: 'column',
            gridTemplateAreas: mediaAlignment === 'right' ? '"content media"' : '"media content"',
        },
        [`${StyledAlignContent}`]: {
            padding: `${theme.space['4']} ${theme.space['3']}`,
            [breakpoints.md]: {
                padding: theme.space['7'],
            },
        },
    }),
    switchProp('mediaAlignment', {
        left: ({ theme }) => ({
            [breakpoints.md]: {
                [`${StyledAlignContent}`]: {
                    marginLeft: theme.space[7],
                },
            },
        }),
        right: ({ theme }) => ({
            [breakpoints.md]: {
                [`${StyledAlignContent}`]: {
                    paddingRight: `calc(${theme.space['7']} + ${theme.space['8']})`,
                },
            },
        }),
    }),

    switchProp(
        'mediaSize',
        {
            default: {
                gridAutoColumns: '1fr 1fr',
            },
            small: ({ mediaAlignment }) => ({
                gridAutoColumns: mediaAlignment === 'right' ? '1fr 350px' : '350px 1fr',
            }),
        },
        'default',
    ),

    // Ensures content aligns with the edge
    // if there is no background color
    ifProp({ backgroundColor: 'none' }, ({ mediaAlignment }) => ({
        [`${StyledAlignContent}`]: {
            paddingLeft: mediaAlignment === 'left' ? undefined : 0,
            paddingRight: mediaAlignment === 'right' ? undefined : 0,
        },
    })),
);
